import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="faq-accordion"
export default class extends Controller {
  connect() {
  }

  toggle(event) {
    const trigger = event.currentTarget;
    const question = trigger.closest('dt');
    const container = question.parentElement;
    const answer = question.nextElementSibling;

    container.dataset.open = answer.hidden;
    question.ariaExpanded = answer.hidden;
    answer.hidden = !answer.hidden;
  }
}
