// @flow
const Rails = require('@rails/ujs');
Rails.start();

if (window) {
  window.Rails = Rails;
}

// extra functions are from https://github.com/nefe/You-Dont-Need-jQuery/blob/master/README.md

Rails.replaceElement = function replaceElement(currentEl, newEl) {
  currentEl.parentNode.replaceChild(newEl, currentEl);
};

Rails.nodeFromString = function(str) {
  const range = document.createRange();
  const parse = range.createContextualFragment.bind(range);

  return parse(str);
};
