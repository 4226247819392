import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin-visual-assets-manager"
export default class extends Controller {
  static targets = ['exactUrlFilterRow'];
  exactUrlsAreShowing = false;

  toggleExactUrls() {
    if (this.exactUrlsAreShowing) {
      this.exactUrlsAreShowing = false;
      this.hideExactUrls();
    } else {
      this.exactUrlsAreShowing = true;
      this.showExactUrls();
    }
  }

  showExactUrls() {
    this.exactUrlFilterRowTargets.forEach((el) => {
      el.classList.toggle('hide');
    });
  }

  hideExactUrls() {
    this.exactUrlFilterRowTargets.forEach((el) => {
      el.classList.toggle('hide');
    });
  }

  showSaveChangesButton(e) {
    const form = e.target.closest('form');
    const saveButton = form.querySelector('[type="submit"]');

    saveButton.classList.remove('hide');
  }
}
